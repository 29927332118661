import { CostForecastRequest, CostForecastRequested } from '@apis/Invoices/model';
import { JobOfCostForecast, QueryOperation } from '@apis/Resources';
import { IDashboardConfigBase, DashboardPersistenceService } from '@root/Components/DashboardPersistence/DashboardPersistenceService';
import { eachMonthOfInterval, format, addDays, eachDayOfInterval, addMinutes } from 'date-fns';
import { injectable, inject } from 'tsyringe';
import { FormatService } from '../FormatService';

export type SavedForecast = { forecastJobId: string } & IDashboardConfigBase;

export const standardGroupOptions = [
    { label: 'Service Code', value: 'product/servicecode' },
    { label: 'Usage Type', value: 'lineItem/UsageType' },
    { label: 'Product Family', value: 'product/productFamily' },
    { label: 'Product Name', value: 'product/ProductName' },
    { label: 'Region', value: 'product/region' },
    { label: 'Usage Account Id', value: 'lineItem/UsageAccountId' },
    { label: 'Usage Account Name', value: 'lineItem/UsageAccountName' },
    { label: 'Line Item Type', value: 'lineItem/LineItemType' },
];

const standardGroupLookup = standardGroupOptions.reduce((result, item) => result.set(item.value, item.label), new Map<string, string>());

export function getGroupOptionsByFields(requestedFields: string[], validFields: string[]) {
    const validFieldLookup = getGroupOptions(validFields).reduce(
        (result, item) => result.set(item.value, item),
        new Map<string, { label: string; value: string }>()
    );
    return requestedFields.flatMap((o) => (validFieldLookup.has(o) ? [validFieldLookup.get(o)!] : []));
}
export function getGroupOptions(includedFields: string[]) {
    const camelToUfCase = (text: string) => {
        return text
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            .replace(/^[a-z]/, (v) => v.toLocaleUpperCase());
    };
    return [
        ...standardGroupOptions,
        ...includedFields
            .filter((f) => !standardGroupLookup.has(f))
            .map((f) => {
                if (f.startsWith('resourceTags/user:')) {
                    return { label: 'Tag: ' + f.substring('resourceTags/user:'.length), value: f };
                } else {
                    const [namespace, field] = f.split('/');
                    const ufName = camelToUfCase(field);
                    return { label: `${namespace}: ${ufName}`, value: f };
                }
            }),
    ];
}

export type CommonForecastDetails = { historicalFrom: string; historicalTo: string; forecastDays: number; groups: string[] };

@injectable()
export class CostForecastPersistence {
    public constructor(
        @inject(DashboardPersistenceService) private readonly dashboardPersistenceSvc: DashboardPersistenceService,
        @inject(FormatService) private readonly fmtSvc: FormatService
    ) {}

    public getEta(historicalDays: number, cardinality: number, actualRecords?: number) {
        const minutesPerLabelDay = 0.000318;
        const worstCaseRecords = cardinality * historicalDays;
        const sparsenessFactor = actualRecords ? actualRecords / worstCaseRecords : 1;
        const minutes = cardinality * historicalDays * minutesPerLabelDay;
        const desparseMinutes = Math.max(Math.round(minutes * sparsenessFactor), 5);
        const currentDate = new Date();
        const endDate = addMinutes(currentDate, desparseMinutes);
        const label = minutes < 60 * 3 ? this.fmtSvc.timeElapsed(currentDate, endDate) : '> 6 hours';
        return { label, minutes };
    }

    public async rename(jobId: string, name: string) {
        const forecastDashboards = await this.dashboardPersistenceSvc.getLayouts<SavedForecast>('CostForecast');
        const dashboard = forecastDashboards.find((d) => d.layout.forecastJobId === jobId);
        if (dashboard) {
            dashboard.layout.name = name;
            await this.dashboardPersistenceSvc.save('CostForecast', dashboard.id, dashboard.layout);
            return true;
        }
        return false;
    }

    public async saveFromJob(job: JobOfCostForecast) {
        if (!job.Id) {
            throw new Error('Job does not have an ID');
        }

        await this.dashboardPersistenceSvc.save('CostForecast', undefined, {
            name: this.createDefaultForecastName(job),
            forecastJobId: job.Id,
        } as IDashboardConfigBase);
    }

    public async getMyCostForecasts() {
        const forecastDashboards = await this.dashboardPersistenceSvc.getLayouts<SavedForecast>('CostForecast');
        return forecastDashboards.map((d) => ({ savedForecast: d.layout, owner: d.ownerUserId }));
    }

    private createDefaultForecastName(job: JobOfCostForecast) {
        const rangeInfo = this.getForecastRange(job);
        const from = rangeInfo?.forecastRange.from!;
        const to = rangeInfo?.forecastRange.to!;
        const months = eachMonthOfInterval({ start: from, end: to });
        const multiYear = months.reduce((result, item) => result.add(item.getFullYear()), new Set<number>()).size > 1;
        const days = job.Parameters.DaysToForecast ?? 0;
        const range = multiYear
            ? `${this.fmtSvc.toShortDate(from)} — ${this.fmtSvc.toShortDate(to)}`
            : months.length > 1
            ? `${this.fmtSvc.formatMonthDay(from)} — ${this.fmtSvc.toShortDate(to)}`
            : `${format(from, 'LLL do')} — ${format(to, 'do, yyyy')}`;

        return `${days} Day Forecast (${range})`;
    }

    public getValidGroupDescriptors(fields: string[], forecastOptionsValidGroups: { Groups?: string[] | null } | null) {
        return getGroupOptionsByFields(fields, forecastOptionsValidGroups?.Groups ?? []);
    }
    public getGroupOptions(forecastOptions: { Groups?: string[] | null } | null) {
        if (!forecastOptions?.Groups?.length) {
            return { groupOptions: [], groupLookup: new Map<string, string>() };
        }
        const availGroups = forecastOptions.Groups;
        const groupOptions = getGroupOptionsByFields(availGroups, availGroups);
        const groupLookup = groupOptions.reduce((result, item) => result.set(item.value, item.label), new Map<string, string>());

        return { groupOptions, groupLookup };
    }

    public createDescription(request: JobOfCostForecast | CostForecastRequest) {
        request = 'Parameters' in request ? request.Parameters : request;
        const accounts = this.getAccounts(request);
        const accountLbls = accounts.map((acc) => this.fmtSvc.awsAccountId(acc));
        const accountLbl = !accounts.length ? '' : accountLbls[0] + (accountLbls.length > 1 ? ` +${accountLbls.length - 1}` : '');

        const trainFrom = this.fmtSvc.parseDateNoTime(request.SpendHistoryStartDate ?? '');
        const trainTo = this.fmtSvc.parseDateNoTime(request.SpendHistoryEndDate ?? '');
        const trainingPeriodLbl = this.fmtSvc.formatDate(trainFrom) + ' — ' + this.fmtSvc.formatDate(trainTo);

        const { groupOptions, groupLookup } = this.getGroupOptions(request);
        const dimensions = request.Groups?.map((g) => groupLookup.get(g)!) ?? [];
        const extraDimensions = dimensions.slice();
        dimensions.push('Service Code');
        const dimensionsLbl = extraDimensions.join(', ') ?? '';
        const allDimensionsLbl = dimensions.join(', ') ?? '';

        const otherFilters = request.Filter ?? [];
        const filtersLbl = otherFilters.length > 1 ? `${otherFilters.length} filters` : otherFilters.length === 1 ? '1 filter' : 'No filters';

        const description = ['Analysis Period: ' + trainingPeriodLbl, 'Dimensions: ' + allDimensionsLbl, filtersLbl].filter((d) => d).join('; ');

        return {
            description,
            accountLbls,
            accountLbl,
            trainFrom,
            trainTo,
            trainingPeriodLbl,
            filterCt: otherFilters.length,
            filtersLbl,
            dimensions,
            extraDimensions,
            dimensionsLbl,
            groupOptions,
        };
    }

    public getAccounts(model: CostForecastRequested) {
        return model.Accounts ?? [];
    }

    public createJobName(job: JobOfCostForecast) {
        return this.createDefaultForecastName(job);
    }

    public getCommonDetailsFromJob(job: JobOfCostForecast) {
        return {
            historicalFrom: job.Parameters.SpendHistoryStartDate ?? '',
            historicalTo: job.Parameters.SpendHistoryEndDate ?? '',
            forecastDays: job.Parameters.DaysToForecast ?? 0,
            groups: job.Parameters.Groups ?? [],
        };
    }

    public getForecastRange(job?: JobOfCostForecast | CommonForecastDetails) {
        if (job) {
            job = 'Parameters' in job ? this.getCommonDetailsFromJob(job) : job;
            const historicalFrom = this.fmtSvc.parseDateNoTime(job.historicalFrom);
            const rawHistoricalTo = this.fmtSvc.parseDateNoTime(job.historicalTo);
            const historicalTo = rawHistoricalTo.getFullYear() < historicalFrom.getFullYear() ? new Date() : rawHistoricalTo;
            const forecastTo = addDays(historicalTo, job.forecastDays);
            const days = eachDayOfInterval({ start: historicalFrom, end: forecastTo });
            const forecastDays = days.filter((d) => d >= historicalTo);
            const months = eachMonthOfInterval({ start: historicalFrom, end: forecastTo });
            const intervalThresholds = [
                { interval: 1, threshold: 130 },
                { interval: 7, threshold: 700 },
            ];
            const getIntervals = (days: number) => {
                const result: (number | 'month')[] = intervalThresholds
                    .filter((t) => days <= t.threshold && days / t.interval > 2)
                    .map((t) => t.interval);
                if (days > 31) {
                    result.push('month');
                }
                return result;
            };
            const intervalOptions = getIntervals(days.length);
            const forecastIntervalOptions = getIntervals(forecastDays.length);

            return {
                days,
                months,
                getDayIncrements(interval: number | 'month', includeHistorical: boolean = true) {
                    return interval === 1 && includeHistorical
                        ? days
                        : interval === 1 && !includeHistorical
                        ? forecastDays
                        : interval === 'month' && includeHistorical
                        ? months
                        : interval === 'month' && !includeHistorical
                        ? eachMonthOfInterval({ start: historicalTo, end: forecastTo })
                        : eachDayOfInterval(
                              { start: includeHistorical ? historicalFrom : historicalTo, end: forecastTo },
                              { step: interval as number }
                          );
                },
                intervalOptions,
                forecastIntervalOptions,
                forecastDays,
                forecastRange: { from: historicalTo, to: forecastTo },
                historicalRange: { from: historicalFrom, to: historicalTo },
            };
        } else {
            return undefined;
        }
    }
}
